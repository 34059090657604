import type { Dateable } from "../../utils/types";
import { useDate } from "../DateProvider";
import getClientTimeZone from "../../utils/getClientTimeZone";
import formatDateTime from "../../utils/formatDateTime";

interface DateTimeProps {
  /** Date to show */
  date: Dateable;

  /** Format date should be in */
  format?: string;

  /** Force into browser timezone */
  ignoreProfileTimezone?: boolean;
}

function DateTime(props: DateTimeProps) {
  const { date, format } = props;
  // Get timezone from AppProvider (if exists)
  const { timezone } = useDate();

  // fallback to client timezone then to UTC if that fails
  const clientTimezone =
    (props.ignoreProfileTimezone ? null : timezone) ||
    getClientTimeZone() ||
    "UTC";

  const formattedDate = formatDateTime(
    date,
    format || "default",
    clientTimezone,
  );
  const formattedISODate = formatDateTime(date, "iso", clientTimezone);

  return (
    <time dateTime={formattedISODate} title={formattedDate}>
      {formattedDate}
    </time>
  );
}

export default DateTime;

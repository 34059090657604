import { useCallback, useMemo } from "react";
import { useAtomValue } from "jotai";
import { useCurrentAccountId } from "@zapier/identity";
import { uuid, canvasAtom, sessionAtom } from "@zapier/canvas-state";

import {
  RESOURCE_CREATED_EVENT,
  USER_INTERACTION_EVENT,
  CREATE_NEW_TABLE_BUTTON_TEXT,
  CREATE_NEW_INTERFACE_BUTTON_TEXT,
  CREATE_NEW_ZAP_BUTTON_TEXT,
  CREATE_NEW_CHATBOTS_BUTTON_TEXT,
} from "@zapier/canvas-constants";

export function useEmitResourceCreatedEvents() {
  const currentAccountId = useCurrentAccountId();
  const session = useAtomValue(sessionAtom);
  const canvas = useAtomValue(canvasAtom);
  const canvasId = canvas.id;

  const commonProps = useMemo(
    () => ({
      customuser_id: session?.id,
      account_id: currentAccountId,
    }),
    [currentAccountId, session?.id],
  );

  const commonPropsInteractionEvent = useMemo(
    () => ({
      system_name: "canvas",
      canvas_id: canvasId,
      ...commonProps,
    }),
    [canvasId, commonProps],
  );

  const emitEventOnZapCreation = useCallback(
    (zapId: string) => {
      if (window.EventToolkit?.emit) {
        const user_interaction_event_id = uuid();

        window.EventToolkit.emit(
          {
            event_id: user_interaction_event_id,
            event_action: "click",
            interaction_goal: "Create zap draft",
            interaction_raw_text: CREATE_NEW_ZAP_BUTTON_TEXT,
            ...commonPropsInteractionEvent,
          },
          USER_INTERACTION_EVENT,
        );

        window.EventToolkit.emit(
          {
            event_id: uuid(),
            resource_system: "Zap",
            resource_id: zapId,
            user_interaction_event_id,
            ...commonProps,
          },
          RESOURCE_CREATED_EVENT,
        );
      }
    },
    [commonPropsInteractionEvent, commonProps],
  );

  const emitEventOnTableCreation = useCallback(
    (tableId: string) => {
      if (window.EventToolkit?.emit) {
        const user_interaction_event_id = uuid();

        window.EventToolkit.emit(
          {
            event_id: user_interaction_event_id,
            event_action: "click",
            interaction_goal: "Create Zapier Table",
            interaction_raw_text: CREATE_NEW_TABLE_BUTTON_TEXT,
            ...commonPropsInteractionEvent,
          },
          USER_INTERACTION_EVENT,
        );

        window.EventToolkit.emit(
          {
            event_id: uuid(),
            resource_system: "Tables",
            resource_id: tableId,
            user_interaction_event_id,
            ...commonProps,
          },
          RESOURCE_CREATED_EVENT,
        );
      }
    },
    [commonPropsInteractionEvent, commonProps],
  );

  const emitEventOnInterfaceCreation = useCallback(
    (projectId: string) => {
      if (window.EventToolkit?.emit) {
        const user_interaction_event_id = uuid();

        window.EventToolkit.emit(
          {
            event_id: user_interaction_event_id,
            event_action: "click",
            interaction_goal: "Create Zapier Interface",
            interaction_raw_text: CREATE_NEW_INTERFACE_BUTTON_TEXT,
            ...commonPropsInteractionEvent,
          },
          USER_INTERACTION_EVENT,
        );

        window.EventToolkit.emit(
          {
            event_id: uuid(),
            resource_system: "Interfaces",
            resource_id: projectId,
            user_interaction_event_id,
            ...commonProps,
          },
          RESOURCE_CREATED_EVENT,
        );
      }
    },
    [commonPropsInteractionEvent, commonProps],
  );

  const emitEventOnChatbotCreation = useCallback(
    (chatbotId: string) => {
      if (window.EventToolkit?.emit) {
        const user_interaction_event_id = uuid();

        window.EventToolkit.emit(
          {
            event_id: user_interaction_event_id,
            event_action: "click",
            interaction_goal: "Create Zapier Interface",
            interaction_raw_text: CREATE_NEW_CHATBOTS_BUTTON_TEXT,
            ...commonPropsInteractionEvent,
          },
          USER_INTERACTION_EVENT,
        );
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            resource_system: "Chatbots",
            resource_id: chatbotId,
            user_interaction_event_id,
            ...commonProps,
          },
          RESOURCE_CREATED_EVENT,
        );
      }
    },
    [commonProps, commonPropsInteractionEvent],
  );

  return {
    emitEventOnZapCreation,
    emitEventOnTableCreation,
    emitEventOnInterfaceCreation,
    emitEventOnChatbotCreation,
  };
}

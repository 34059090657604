import formatDate from "intl-dateformat";

import ensureDate from "../ensureDate";

import { Dateable } from "../types";

type PredefinedFormatType = "default" | "short" | "exhaustive" | "iso";

type PredefinedFormatMap = Record<PredefinedFormatType | string, string>;

const PREDEFINED_FORMATS: PredefinedFormatMap = {
  default: "MMMM DD, YYYY",
  short: "MMM DD, YYYY",
  exhaustive: "YYYY-MM-DD HH:mm:ss",
  iso: "YYYY-MM-DD:mm:ssZ",
};

const formatDateTime = (
  dateTime: Dateable,
  format: PredefinedFormatType | string,
  timeZone?: string
): string => {
  const parsedDate = ensureDate(dateTime);

  if (format === "iso") return parsedDate.toISOString();

  try {
    return formatDate(
      parsedDate,
      PREDEFINED_FORMATS[format] || format,
      timeZone ? { timezone: timeZone } : undefined
    );
  } catch (err) {
    // `intl-dateformat` uses `Intl.DateTimeFormat` under the hood.
    // In order to still support legacy browsers, we fallback on `Date#toLocalDateString`.
    try {
      return parsedDate.toLocaleDateString(
        "en-US",
        timeZone ? { timeZone } : undefined
      );
    } catch (err2) {
      // In some edge-cases the backend can send non-IANA timezones.
      // If so we can still try to format the date correctly with no timezone.
      return formatDateTime(dateTime, format);
    }
  }
};

export default formatDateTime;

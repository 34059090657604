import * as React from "react";

function useTimer(onComplete: () => void, time: number) {
  const start = React.useRef(time);
  const remaining = React.useRef(time);
  const timer = React.useRef<NodeJS.Timeout | undefined>();

  function onClear() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }

  function onPause() {
    onClear();
    remaining.current -= Date.now() - start.current;
  }

  function onResume() {
    onClear();
    start.current = Date.now();
    timer.current = setTimeout(onComplete, remaining.current);
  }

  React.useEffect(() => {
    onResume();
    return () => {
      onPause();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onComplete]);

  return {
    onClear,
    onPause,
    onResume,
  };
}

export default useTimer;

import { Alert } from "@zapier/design-system";
import { Notification as NotificationType } from "@zapier/canvas-state";

import useTimer from "./useTimer";
import styles from "./Notification.module.css";

type Props = {
  index: number;
  notification: NotificationType;
  onDismiss: (id: string) => void;
  timeout: number;
};

/**
 * A popup toast-style notification
 */
export function Notification({ notification, onDismiss, timeout }: Props) {
  function handleDismiss() {
    if (notification.onDismiss) {
      notification.onDismiss(notification);
    }
    onDismiss(notification.id);
  }

  const { onPause, onResume } = useTimer(handleDismiss, timeout);

  return (
    <div className={styles.root} onMouseEnter={onPause} onMouseLeave={onResume}>
      <Alert onDismiss={handleDismiss} type={notification.type}>
        {notification.message}
      </Alert>
    </div>
  );
}

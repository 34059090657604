import { useCallback, useMemo } from "react";
import { useCurrentAccountId } from "@zapier/identity";
import { useAtomValue } from "jotai";
import { sessionAtom, canvasAtom } from "@zapier/canvas-state";

import { uuid } from "./utils/uuid";

import {
  RESOURCE_CONNECT_EVENT,
  USER_INTERACTION_EVENT,
} from "@zapier/canvas-constants";

declare global {
  interface Window {
    EventToolkit: {
      emit(options: Record<string, any>, event: string): any;
    };
  }
}
export function useEmitResourceConnectEvents() {
  const currentAccountId = useCurrentAccountId();
  const session = useAtomValue(sessionAtom);
  const canvas = useAtomValue(canvasAtom);
  const canvasId = canvas.id;

  const commonProps = useMemo(
    () => ({
      customuser_id: session?.id,
      account_id: currentAccountId,
    }),
    [currentAccountId, session?.id],
  );

  const commonPropsInteractionEvent = useMemo(
    () => ({
      system_name: "canvas",
      canvas_id: canvasId,
      ...commonProps,
    }),
    [canvasId, commonProps],
  );

  const emitEventOnZapConnect = useCallback(
    (zapId?: string) => {
      if (window.EventToolkit?.emit) {
        const user_interaction_event_id = uuid();

        window.EventToolkit.emit(
          {
            event_id: user_interaction_event_id,
            event_action: "select",
            interaction_goal: "Link to existing Zap",
            interaction_raw_text: null,
            ...commonPropsInteractionEvent,
          },
          USER_INTERACTION_EVENT,
        );

        window.EventToolkit.emit(
          {
            event_id: uuid(),
            resource_system: "Zap",
            resource_id: zapId,
            user_interaction_event_id,
            ...commonProps,
          },
          RESOURCE_CONNECT_EVENT,
        );
      }
    },
    [commonPropsInteractionEvent, commonProps],
  );

  const emitEventOnTableConnect = useCallback(
    (tableId: string) => {
      if (window.EventToolkit?.emit) {
        const user_interaction_event_id = uuid();

        window.EventToolkit.emit(
          {
            event_id: user_interaction_event_id,
            event_action: "select",
            interaction_goal: "Link to existing Zapier Table",
            interaction_raw_text: null,
            ...commonPropsInteractionEvent,
          },
          USER_INTERACTION_EVENT,
        );

        window.EventToolkit.emit(
          {
            event_id: uuid(),
            resource_system: "Tables",
            resource_id: tableId,
            user_interaction_event_id,
            ...commonProps,
          },
          RESOURCE_CONNECT_EVENT,
        );
      }
    },
    [commonPropsInteractionEvent, commonProps],
  );

  const emitEventOnInterfaceConnect = useCallback(
    (projectId: string) => {
      if (window.EventToolkit?.emit) {
        const user_interaction_event_id = uuid();

        window.EventToolkit.emit(
          {
            event_id: user_interaction_event_id,
            event_action: "select",
            interaction_goal: "Link to existing Zapier Interface",
            interaction_raw_text: null,
            ...commonPropsInteractionEvent,
          },
          USER_INTERACTION_EVENT,
        );

        window.EventToolkit.emit(
          {
            event_id: uuid(),
            resource_system: "Interfaces",
            resource_id: projectId,
            user_interaction_event_id,
            ...commonProps,
          },
          RESOURCE_CONNECT_EVENT,
        );
      }
    },
    [commonPropsInteractionEvent, commonProps],
  );

  const emitEventOnChatbotConnect = useCallback(
    (chatbotId: string) => {
      if (window.EventToolkit?.emit) {
        const user_interaction_event_id = uuid();

        window.EventToolkit.emit(
          {
            event_id: user_interaction_event_id,
            event_action: "select",
            interaction_goal: "Link to existing Zapier Chatbot",
            interaction_raw_text: null,
            ...commonPropsInteractionEvent,
          },
          USER_INTERACTION_EVENT,
        );

        window.EventToolkit.emit(
          {
            event_id: uuid(),
            resource_system: "Chatbots",
            resource_id: chatbotId,
            user_interaction_event_id,
            ...commonProps,
          },
          RESOURCE_CONNECT_EVENT,
        );
      }
    },
    [commonPropsInteractionEvent, commonProps],
  );

  return {
    emitEventOnZapConnect,
    emitEventOnTableConnect,
    emitEventOnInterfaceConnect,
    emitEventOnChatbotConnect,
  };
}

"use client";

import {
  AvatarWithTooltip,
  Icon,
  Spacer,
  Spinner,
  TagLabel,
  Text,
} from "@zapier/design-system";
import { Button } from "@zapier/design-system-beta";
import { useCallback, useEffect, useState } from "react";
import { UniversalLayout } from "@zapier/universal-layout";
import { DateTime } from "@zapier/date-canvas";
import {
  CurrentAccountIdProvider,
  useCurrentAccountId,
} from "@zapier/identity";
import { useAtomValue, useSetAtom } from "jotai";
import "./wootric.css";
import { Notifications } from "@zapier/canvas/src/components/Notifications";
import { useEmitUserInteractionEvents } from "@zapier/observability";
import {
  ALLOWED_TEMPLATE_SLUGS,
  PAGE_VIEW_EVENT,
} from "@zapier/canvas-constants";
import { redirect } from "next/navigation";
import { ActionMenu } from "@zapier/canvas/src/components/ActionMenu";
import {
  Canvas,
  accountAtom,
  sessionAtom,
  createNotificationAtom,
  ZSL,
  emitEventOnNewCanvasCreationAtom,
} from "@zapier/canvas-state";
import { useWootric } from "@zapier/canvas/src/hooks/useWootric";
import useIsOwnerOrSuperAdmin from "@zapier/canvas/src/hooks/useIsOwnerOrSuperAdmin";

import { deleteCanvas, DeleteCanvasErrorType } from "@zapier/canvas";
import { ZeroStateHeader, ZeroStateTemplates } from "@zapier/platform-ui";
import styles from "./page.module.css";

function CanvasListPage() {
  const session = useAtomValue(sessionAtom);
  const account = useAtomValue(accountAtom);
  const currentAccountId = useCurrentAccountId();
  const [isCanvasListLoading, setIsCanvasListLoading] = useState(true);
  const [canvases, setCanvases] = useState<Canvas[]>([]);

  const isAccountOwnerOrSuperAdmin = useIsOwnerOrSuperAdmin();

  const createNotification = useSetAtom(createNotificationAtom);
  useWootric({ canvasCount: canvases.length, account, session });

  const { emitEventOnDeleteCanvas } = useEmitUserInteractionEvents();
  const emitEventOnNewCanvasCreation = useSetAtom(
    emitEventOnNewCanvasCreationAtom,
  );

  const getList = useCallback(
    async (showLoader = true) => {
      if (showLoader) {
        setIsCanvasListLoading(true);
      }

      const res = await fetch("/api/canvas/v1/canvases/", {
        headers: {
          "trailing-slash-hack": "true",
        },
      });
      const json = await res.json();
      setCanvases(json);

      if (window.EventToolkit?.emit && currentAccountId) {
        window.EventToolkit?.emit(
          {
            system_name: "canvas",
            account_id: currentAccountId,
          },
          PAGE_VIEW_EVENT,
        );
      }

      if (showLoader) {
        setIsCanvasListLoading(false);
      }
    },
    [currentAccountId],
  );

  useEffect(() => {
    void getList();
  }, [getList]);

  const onDeleteCanvas = useCallback(
    async (canvas: Canvas) => {
      const canvasName = canvas.name;

      try {
        const res = await deleteCanvas(canvas.id!);

        if (res.error) {
          throw res.error; // This throws the error object to be caught by the catch block
        }

        await getList(false);

        emitEventOnDeleteCanvas();

        createNotification({
          type: "success",
          message: canvasName
            ? `Your "${canvasName}" canvas has been deleted.`
            : "Your canvas has been deleted.",
        });
      } catch (error) {
        const apiError = error as DeleteCanvasErrorType;

        let errorMessage = canvasName
          ? `We ran into an issue trying to delete your "${canvasName}" canvas. Please try again.`
          : "We ran into an issue trying to delete your canvas. Please try again.";

        if (apiError.json?.reason === "PROJECTS_API_ERROR") {
          errorMessage = `We couldn't delete your Canvas because we ran into an issue trying to delete it from the project.`;
        }

        createNotification({
          type: "error",
          message: errorMessage,
        });
      }
    },
    [createNotification, getList, emitEventOnDeleteCanvas],
  );

  const haveCanvases = !isCanvasListLoading && canvases.length > 0;

  if (session?.is_logged_in === false && typeof window !== "undefined") {
    return redirect("/app/login");
  }

  const handleCreateCanvas = async () => {
    await emitEventOnNewCanvasCreation();
    /* Triggers a full page reload (don't use router.push or else we will have
    bugs when you press back button and press "Create" button again - see CNVS-1731) */
    window.location.href = "/app/canvas/new";
  };

  const handleTemplateCreation = async ({ zsl }: { zsl: ZSL }) => {
    if (!zsl?.key) return;
    window.open(`/app/canvas/template/${zsl.key}`, "_blank");
  };

  const renderZeroState = () => {
    if (!isCanvasListLoading && canvases.length === 0) {
      return (
        <section>
          <ZeroStateHeader
            description="Canvas is a collaborative diagramming tool that helps you plan, document, and convey the value of your workflows."
            highlights={[
              {
                text: "Diagram",
                icon: "actionRename",
              },
              {
                text: "Connect",
                icon: "navApps",
              },
              {
                text: "Collaborate",
                icon: "commentConvo",
              },
            ]}
            productIcon="navCanvas"
            productName="Canvas"
            actionButtons={[
              {
                text: "Start blank",
                icon: "formAdd",
                size: "small",
                onClick: handleCreateCanvas,
              },
            ]}
            videoDescription={`"Welcome to Zapier Canvas! It's a living breathing diagram for every part..."`}
            videoThumbnail="https://res.cloudinary.com/zapier-media/image/upload/q_auto,f_auto/v1718298324/Canvas_Intro_Thumbnail_Play_ALT_qnftd9.png"
            youTubeId="j6Y_bEVOj00"
          />
          <ZeroStateTemplates
            contentfulAccessToken="I6hQ4KkDR1Y85c6taFMwRcYcwP0jrCKVoh60gkAUZ6E"
            hideFilters
            createTemplate={handleTemplateCreation}
            showCanvasImages
            templateFilter={(template) =>
              ALLOWED_TEMPLATE_SLUGS.includes(template.key)
            }
            title="Templates"
          />
        </section>
      );
    }
  };

  return (
    <UniversalLayout
      currentUrl={"/app/canvas"}
      mainContentId="canvas-main"
      trackEvent={() => null}
      showSimplifiedFooter={true}
    >
      <div className={styles.wrapper}>
        {isCanvasListLoading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
        {haveCanvases && (
          <>
            <span className={styles.header}>
              <span className={styles.title}>
                <Text type="pageHeader7">Canvas</Text>
                <TagLabel size="small" color="simple">
                  Beta
                </TagLabel>
              </span>
              <span>
                <Button onPress={handleCreateCanvas}>
                  <Icon name="formAdd" />
                  Create
                </Button>
              </span>
            </span>
            <Header />
            <ul className={styles.list}>
              {canvases.map((canvas) => (
                <li key={canvas.id} className={styles.listItem}>
                  <div className={styles.linkWrapper}>
                    <a href={`/app/canvas/${canvas.id}`}>
                      <div className={styles.linkRow}>
                        <div className={styles.nameCol}>
                          <Text type="paragraph3">
                            {canvas.name || "untitled"}
                          </Text>
                        </div>
                        <Text type="paragraph3">
                          {canvas.nodes?.length || 0}
                        </Text>
                        <Text type="paragraph3">
                          {canvas.lastchanged && (
                            <DateTime
                              date={new Date(canvas.lastchanged)}
                              format="MMM DD, YYYY"
                            ></DateTime>
                          )}
                        </Text>
                        <Text type="paragraph3">
                          <Spacer height={5} />
                          {canvas.full_name && (
                            <AvatarWithTooltip
                              name={canvas.full_name}
                              size={25}
                              isInteractive={true}
                              url={canvas.photo_url}
                            />
                          )}
                        </Text>
                      </div>
                    </a>
                  </div>
                  {isAccountOwnerOrSuperAdmin ||
                  session?.id === canvas.customuser_id ? (
                    <div className={styles.actionMenu}>
                      <ActionMenu deleteCanvas={() => onDeleteCanvas(canvas)} />
                    </div>
                  ) : (
                    <div className={styles.actionMenuBlank}>
                      <a href={`/app/canvas/${canvas.id}`}>
                        <span className={styles.visuallyHidden}>
                          Open Canvas
                        </span>
                      </a>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
        {renderZeroState()}
      </div>
    </UniversalLayout>
  );
}

function Header() {
  return (
    <div className={styles.tableHeader}>
      <Text type="smallPrint1Bold">Name</Text>
      <Text type="smallPrint1Bold">Steps</Text>
      <Text type="smallPrint1Bold">Updated</Text>
      <Text type="smallPrint1Bold">Owner</Text>
    </div>
  );
}

export default function PageWrapper() {
  return (
    <CurrentAccountIdProvider>
      <Notifications />
      <CanvasListPage />
    </CurrentAccountIdProvider>
  );
}

import { useEffect } from "react";
import { Account, Session } from "@zapier/canvas-state";

declare global {
  interface Window {
    wootric(command: string): void;
  }
}

type Options = {
  canvasCount: number;
  account: Account | null;
  session: Session | null;
};

export function useWootric({ canvasCount, account, session }: Options) {
  useEffect(() => {
    if (
      !!account &&
      !!session &&
      session.email &&
      // signup is a string on /api/v4/session representing when the user signed up
      session.signup &&
      process.env.NEXT_PUBLIC_VERCEL_ENV !== "development" &&
      !session.is_staff &&
      // Only load wootric survey if canvasCount is more than 0 and if the canvasCount is a number at all
      // We don't want to ask people an NPS survey for a feature they haven't used yet
      typeof canvasCount === "number" &&
      canvasCount > 0
    ) {
      const setupScript = document.createElement("script");
      setupScript.type = "text/javascript";
      setupScript.id = "wootric-settings";
      setupScript.async = true;
      setupScript.innerHTML = `
        window.wootricSettings = {
            email: '${session.email}',
            // This needs to be an integer value representing a datetime, and it needs the
            // _date suffix. It's not a typo.
            created_at: ${new Date(session.signup).getTime() / 1000},
            account_token: 'NPS-edfb676e',
            properties: {
              email: '${session.email}',
              // It's possible timezone is null. It's not required to set
              timezone: '${session.timezone}',
              plan_tier: "${account.plan_info.name}",
              customuser_id: "${session.id}",
              account_id: "${account.id}",
              // You need to add the _amount suffix to raw numbers because wootric is picky
              canvas_amount: ${canvasCount},
              created_at_date: ${new Date(session.signup).getTime() / 1000},
            }
        };  
    `;
      if (document.getElementById("wootric-settings") == null) {
        document.body.appendChild(setupScript);
      }

      // Beacon
      const beacon = document.createElement("script");
      beacon.type = "text/javascript";
      beacon.id = "wootric-beacon";
      beacon.async = true;

      beacon.src = "https://cdn.wootric.com/wootric-sdk.js";
      beacon.onload = function () {
        window.wootric("run");
      };
      if (document.getElementById("wootric-beacon") == null) {
        document.body.appendChild(beacon);
      }
    }
  }, [session, canvasCount, account]);
}

import { Dateable } from "../types";

/**
 * Make sure the given `Dateable` is a `Date`.
 *
 * @param {Dateable} date
 */
const ensureDate = (date: Dateable): Date => {
  // The server sends us UNIX timestamps in seconds, make sure we use JS timestamp in milliseconds
  if (typeof date === "number" && date < 10e9) {
    date *= 1000;
  }
  return new Date(date);
};

export default ensureDate;

interface ErrorJson {
  errors: string;
  reason: string;
}

export type DeleteCanvasErrorType = {
  name: string;
  message: string;
  status?: number;
  json?: ErrorJson;
};

class DeleteCanvasError extends Error {
  status?: number;
  json?: ErrorJson;

  constructor(message: string, status?: number, json?: ErrorJson) {
    super(message);
    this.name = "DeleteCanvasError";
    this.status = status;
    this.json = json;
  }
}

export async function deleteCanvas(canvasId: string) {
  try {
    const res = await fetch(`/api/canvas/v1/canvases/${canvasId}/`, {
      method: "DELETE",
      headers: {
        "trailing-slash-hack": "true",
      },
    });

    if (!res.ok) {
      const errorResponse = await res.json().catch(() => ({
        errors: "Error parsing delete Canvas response data",
        reason: "Invalid JSON",
      }));

      throw new DeleteCanvasError(res.statusText, res.status, errorResponse);
    }

    return {
      status: res.status,
      statusText: res.statusText,
    };
  } catch (error) {
    console.error("Error deleting Canvas:", error);
    return {
      error,
    };
  }
}

import { Fragment, useCallback, useMemo, useState } from "react";
import {
  FloatingBox,
  Menu,
  MenuItem,
  useClickOutside,
} from "@zapier/design-system";
import { IconButton } from "@zapier/design-system-beta";

import styles from "./ActionMenu.module.css";

type Props = {
  deleteCanvas: () => void;
};

enum CanvasActions {
  deleteCanvas,
}

const getDropdownItems = () => [
  {
    label: "Delete",
    value: CanvasActions.deleteCanvas,
  },
];

function ActionMenu({ deleteCanvas }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const items = useMemo(() => getDropdownItems(), []);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleItemClick = useCallback(
    (action: CanvasActions) => () => {
      if (action === CanvasActions.deleteCanvas) {
        deleteCanvas();
      }
    },
    [deleteCanvas],
  );

  const [setClickOutsideNode] = useClickOutside({
    // setTimeout or else the menu closes first and the action of the menu isn't triggered
    onClickOutside: () => setTimeout(() => closeMenu(), 0),
  });

  return items.length === 0 ? null : (
    <div className={styles.root}>
      <Fragment>
        <div ref={setClickOutsideNode}>
          <IconButton
            aria-label="Edit Canvas"
            name="navMoreHoriz"
            size="small"
            variant="tertiary"
            onPress={toggleOpen}
          />
        </div>
        {isOpen && (
          <FloatingBox align="right">
            <div className={styles.menu}>
              <Menu ariaLabel="Member Action Options">
                {items.map((item) => (
                  <MenuItem
                    key={item.value}
                    onClick={handleItemClick(item.value)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </FloatingBox>
        )}
      </Fragment>
    </div>
  );
}

export default ActionMenu;

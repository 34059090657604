import { useCallback, useMemo } from "react";
import { useAtomValue } from "jotai";
import { useCurrentAccountId } from "@zapier/identity";
import {
  canvasAtom,
  uuid,
  sessionAtom,
  AssetType,
  SelectablePermission,
} from "@zapier/canvas-state";

import {
  ACCESS_LABELS,
  USER_INTERACTION_EVENT,
} from "@zapier/canvas-constants";
import { EventAssetType } from "./types";

type Permission = (typeof ACCESS_LABELS)["editor" | "viewer" | "no access"];

export function useEmitUserInteractionEvents() {
  const currentAccountId = useCurrentAccountId();
  const session = useAtomValue(sessionAtom);
  const canvas = useAtomValue(canvasAtom);
  const canvasId = canvas.id;

  const commonProps = useMemo(
    () => ({
      system_name: "canvas",
      canvas_id: canvasId,
      customuser_id: session?.id,
      account_id: currentAccountId,
    }),
    [canvasId, session?.id, currentAccountId],
  );

  const emitEventOnAddStep = useCallback(
    (eventAction?: string, rawText?: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: eventAction || "click",
            interaction_goal: "Add step",
            interaction_raw_text: rawText || "Add step",
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnAddPath = useCallback(
    (eventAction?: string, rawText?: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: eventAction || "click",
            interaction_goal: "Add split path",
            interaction_raw_text: rawText || "Split path",
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnGetRecommendations = useCallback(() => {
    if (window.EventToolkit?.emit) {
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "Get AI recommendations",
          interaction_raw_text: "Get recommendations",
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  }, [commonProps]);

  const emitEventOnZapCreation = useCallback(
    (eventId: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: eventId,
            event_action: "click",
            interaction_goal: "Create zap draft",
            interaction_raw_text: "Create this Zap",
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnZapRecreation = useCallback(
    (eventId: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: eventId,
            event_action: "click",
            interaction_goal: "Re-create zap draft",
            interaction_raw_text: "Re-create this Zap",
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnDownload = useCallback(
    (rawText: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "Download canvas",
            interaction_raw_text: rawText,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnShareModalOpen = useCallback(() => {
    if (window.EventToolkit?.emit) {
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "Open sharing modal",
          interaction_raw_text: "Share",
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  }, [commonProps]);

  // When we change account-level sharing settings
  const emitEventOnShareCanvas = useCallback(
    (permission: Permission) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "Update sharing",
            interaction_raw_text: `${permission} - account`,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  // When we change user-level sharing settings
  const emitEventOnShareCanvasWithUser = useCallback(
    (permission: SelectablePermission) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "Update sharing",
            interaction_raw_text: `${permission} - individual`,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnShareCanvasWithTeam = useCallback(
    (permission: SelectablePermission) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "Update sharing",
            interaction_raw_text: `${permission} - team`,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnHelpClick = useCallback(() => {
    if (window.EventToolkit?.emit) {
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "Help",
          interaction_raw_text: "Help",
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  }, [commonProps]);

  const emitEventOnShareUpsell = useCallback(() => {
    if (window.EventToolkit?.emit) {
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "upsell",
          interaction_raw_text: "Upgrade to enable sharing",
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  }, [commonProps]);

  const emitEventOnCommentsUpsell = useCallback(
    (rawText: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "upsell",
            interaction_raw_text: rawText,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnAddingAssetToProject = useCallback(
    (assetType: AssetType, rawText: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: `Add ${assetType} to a Project`,
            interaction_raw_text: rawText,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnCommentOrThreadCreate = useCallback(
    (isNewThread: boolean) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: isNewThread ? "New Thread" : "New Thread Comment",
            interaction_raw_text:
              "Intentionally not sending customer comment in event payload",
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnChatbotsPlanUpgrade = useCallback(
    (rawText: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "upgrade Chatbots",
            interaction_raw_text: rawText,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnDeleteCanvas = useCallback(() => {
    if (window.EventToolkit?.emit) {
      window.EventToolkit.emit(
        {
          event_id: uuid(),
          event_action: "click",
          interaction_goal: "delete",
          interaction_raw_text: "Delete",
          ...commonProps,
        },
        USER_INTERACTION_EVENT,
      );
    }
  }, [commonProps]);

  const emitEventOnPublicLinkSharing = useCallback(
    (is_public: boolean) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "public URL",
            interaction_raw_text: is_public
              ? "Generate public URL"
              : "Disabled public link sharing",
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnSelectTemplate = useCallback(
    (slug: string) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "Preview template",
            interaction_raw_text: slug,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnAIPrompt = useCallback(
    ({ prompt }: { prompt: string }) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "AI Prompt",
            interaction_raw_text: prompt,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnAssetOpen = useCallback(
    ({
      asset,
      location,
    }: {
      asset: EventAssetType;
      location: "node" | "sidebar" | "project panel";
    }) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "Open Asset",
            option_selected: asset,
            interaction_raw_text: location,
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  const emitEventOnAutoLayout = useCallback(
    ({ forAllSteps }: { forAllSteps: boolean }) => {
      if (window.EventToolkit?.emit) {
        window.EventToolkit.emit(
          {
            event_id: uuid(),
            event_action: "click",
            interaction_goal: "Auto layout",
            interaction_raw_text: forAllSteps
              ? "Clean up layout"
              : "Clean up layout for selected steps",
            ...commonProps,
          },
          USER_INTERACTION_EVENT,
        );
      }
    },
    [commonProps],
  );

  return {
    emitEventOnAddStep,
    emitEventOnAddPath,
    emitEventOnGetRecommendations,
    emitEventOnZapCreation,
    emitEventOnZapRecreation,
    emitEventOnDownload,
    emitEventOnShareCanvas,
    emitEventOnShareCanvasWithUser,
    emitEventOnShareCanvasWithTeam,
    emitEventOnHelpClick,
    emitEventOnShareUpsell,
    emitEventOnCommentsUpsell,
    emitEventOnCommentOrThreadCreate,
    emitEventOnChatbotsPlanUpgrade,
    emitEventOnAddingAssetToProject,
    emitEventOnDeleteCanvas,
    emitEventOnPublicLinkSharing,
    emitEventOnSelectTemplate,
    emitEventOnAIPrompt,
    emitEventOnShareModalOpen,
    emitEventOnAutoLayout,
    emitEventOnAssetOpen,
  };
}

import { useAtom } from "jotai";
import { useCallback } from "react";
import { notificationsAtom } from "@zapier/canvas-state";

import { Notification } from "./Notification";
import styles from "./Notifications.module.css";

type Props = {
  timeout?: number;
};

export function Notifications(props: Props) {
  const [notifications, setNotifications] = useAtom(notificationsAtom);

  const clearNotification = useCallback(
    (id: string) => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    },
    [setNotifications],
  );

  if (!notifications.length) return null;
  const defaultTimeout = props.timeout || 5000;

  return (
    <section className={styles.root} data-test="notifications">
      {notifications.map((notification, i) => (
        <Notification
          index={i}
          key={notification.id}
          notification={notification}
          onDismiss={clearNotification}
          timeout={notification.timeout || defaultTimeout}
        />
      ))}
    </section>
  );
}

import { useAtomValue } from "jotai";
import { sessionAtom } from "@zapier/canvas-state";
import { useCurrentAccountId } from "@zapier/identity";

export default function useIsOwnerOrSuperAdmin() {
  const currentAccountId = useCurrentAccountId();
  const session = useAtomValue(sessionAtom);

  if (!session) return false;

  const accountRole = session.roles?.find(
    (role) => role.account_id === currentAccountId,
  );

  return accountRole?.role === "owner" || accountRole?.role === "super_admin";
}
